import { forwardRef } from "react"
import { tv, VariantProps } from "tailwind-variants"

import useDisableNumberInputScroll from "@/hooks/useDisableNumberInputScroll"

const inputVariants = tv({
  base: "flex w-full rounded-md border border-slate-200 bg-white px-4 py-2 text-xs text-sherpa-blue-950 ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-sm placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 sm:text-base",
  variants: {
    inputSize: {
      regular: "h-14",
      // by default 35 and 45px
      small: "h-[2.1875rem] sm:h-[2.8125rem]",
    },
  },
  defaultVariants: {
    inputSize: "regular",
  },
})

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & VariantProps<typeof inputVariants>

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, type, inputSize = "regular", ...props }, ref) => {
  const internalRef = useDisableNumberInputScroll(ref)

  return <input type={type} className={inputVariants({ inputSize, className })} ref={internalRef} {...props} />
})
Input.displayName = "Input"

export { Input }
